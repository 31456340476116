<template>
  <div class="verify-captcha">
    <h3>{{ $t("Please enter the numbers you see in the image") }}</h3>

    <form class="form-group" @submit.prevent="captchaSubmit">
      <div v-if="formError" class="error-container">
        <p class="error">{{ $t(formError) }}</p>
      </div>

      <div v-if="captchaImage">
        <!-- <label class="login-label">{{ $t("Please enter the result for the following") }}</label> -->
        <div
          v-if="captchaImage"
          :style="{
            backgroundImage: 'url(' + captchaImage + ')',
            'background-repeat': 'no-repeat',
            'background-color': 'white',
            width: '200px',
          }"
          class="captcha-image"
        ></div>
      </div>

      <div>
        <button @click="getAnotherCaptcha" class="button-link" type="button">
          {{ $t("Click to refresh if you can not read") }}
        </button>
      </div>

      <div>
        <!-- <div class="label-container" :dir="localDisplayLang === 'ara' ? 'rtl' : 'ltr'">
          <label class="login-label">{{ $t("The result") }}</label>
          <span class="required">*</span>
        </div> -->
        <div class="input-container" :dir="localDisplayLang === 'ara' ? 'rtl' : 'ltr'">
          <input
            id="captchaText"
            type="text"
            v-model="captchaText"
            :placeholder="`${this.$t('The result')}`"
            @keydown.space.prevent
          />
        </div>
      </div>
      <br />
      <div class="login-button">
        <button class="button-primary login" type="submit">
           <span class="btn-txt-color">{{ $t("Verify") }}</span>
        </button>
      </div>
    </form>

    <div v-if="isLoading">
      <Loading></Loading>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import { eventBus } from "../../../../eventBus";

export default {
  components: {
    Loading: () => import(/* webpackChunkName: "Loading" */ "@/components/Templates/Loading.vue"),
  },

  data() {
    return {
      captchaText: null,
      captchaImage: null,
      captchaRefId: null,
      formError: null,
      localDisplayLang: null,
      isLoading: false,
    };
  },

  watch: {
    formError(val) {
      if (val) {
        setTimeout(() => {
          this.formError = null;
        }, 5000);
      }
    },
  },

  computed: {
    ...mapGetters(["subscriptionList"]),
  },

  created() {
    this.getSubscriberPlans();

    this.getCaptchaData();
  },

  mounted() {
    this.localDisplayLang = localStorage.getItem("setDisplayLanguageCode");
  },

  methods: {
    ...mapActions(["getCaptcha", "getCardDetails", "listSubscription"]),

    ...mapMutations(["setSubscriptionList"]),

    getAnotherCaptcha() {
      this.getCaptchaData();
    },

    getCaptchaData() {
      this.getCaptcha()
        .then((data) => {
          if (data.reason) {
            this.formError = data.reason;
            return;
          }

          this.captchaImage = data.payload;
          this.captchaRefId = data.referenceid;
        })
        .catch((error) => {
          console.log("Captcha error -> ", error);
        });
    },

    captchaSubmit() {
      this.isLoading = true;
      console.log("subscriptionList", this.subscriptionList);

      if (!this.captchaText) {
        this.formError = this.$t("Please enter Captcha");
        this.isLoading = false;

        return;
      }

      const payload = {
        gatewayId: this.subscriptionList && this.subscriptionList.length ? this.subscriptionList[0].gwprovider : "",
      };

      payload.captcha = {
        text: this.captchaText,
        referenceid: this.captchaRefId,
      };

      this.getCardDetailsApiCall(payload);

      // this.getCardDetails(payload)
      //   .then((response) => {
      //     this.isLoading = false;
      //     if (!response.reason) {
      //       this.$emit("switch", { switchTo: "UpdateCardDetails", response });
      //     } else {
      //       // Session expiry
      //       if (response.statusCode === 401) {
      //         eventBus.$on("re-login-success", () => {
      //           this.getCardDetails(payload);
      //           eventBus.$off("re-login-success");
      //         });
      //         return;
      //       }

      //       this.formError = this.$t(response.reason);
      //     }
      //   })
      //   .catch((err) => {
      //     this.isLoading = false;
      //     console.error("Captcha Submit error -> ", err);
      //   });
    },

    getCardDetailsApiCall(payload) {
      this.getCardDetails(payload)
        .then((response) => {
          this.isLoading = false;
          if (!response.reason) {
            this.$emit("switch", { switchTo: "UpdateCardDetails", response });
          } else {
            // Session expiry
            if (response.statusCode === 401) {
              eventBus.$on("re-login-success", () => {
                this.getCardDetailsApiCall(payload);
                eventBus.$off("re-login-success");
              });
              return;
            }

            this.formError = this.$t(response.reason);
          }
        })
        .catch((err) => {
          this.isLoading = false;
          console.error("Captcha Submit error -> ", err);
        });
    },

    getSubscriberPlans() {
      let payload = {
        subscriptionstatus: "ALL",
      };

      // this.listSubscription(payload).then((response) => {
      //   console.log("NEW LIST NOW - ", response.data.data);
      //   this.setSubscriptionList(response.data.data);
      // });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/sass/_variables.scss";
@import "@/sass/_components.scss";

.verify-captcha {
  margin: 0 1.5rem;

  width: 50%;
}

.button-link {
  color: $clr-light-gd1;
  font-family: $font-regular;
}

.captcha {
  &-image {
    margin: 10px 0;
    height: 65px;
    background-size: 100%;
  }
}

.form-group {
  margin: 1rem 0;
}

.login {
  width: 25%;
}

@media only screen and(max-width:500px) {
  .verify-captcha {
    margin: 0 0rem;
    width: 100%;
  }

  .login {
    width: 100%;
  }
}

@media only screen and (min-width: 501px) and(max-width:1000px) {
  .verify-captcha {
    margin: 0 0rem;
    width: 50%;
  }

  .login {
    width: 50%;
  }
}
</style>
